import { sendEvent } from "@/utils/internal_tracker_instance";
import { generateSessionId } from "@/utils/generate_session_id";
import * as eventNames from "@/constants/event_names/register";
import getRefferalTracker from "@/utils/get_referral_tracker";

const {
  user: { identity },
} = global["__ACCOUNTS_FE_CONTEXT__"];
const SESSION_ID = generateSessionId(identity);

export const registerHandler = {
  [eventNames.simplifiedRegistrationLandingPage]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.simplifiedRegistrationLandingPage,
        session_id: SESSION_ID,
        page_referrer: getRefferalTracker(),
        ...payload,
      },
    });
  },
  [eventNames.simplifiedRegistrationLandingPageActionRegister]: (
    payload = {},
  ) => {
    sendEvent({
      data: {
        evn: eventNames.simplifiedRegistrationLandingPageActionRegister,
        session_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.simplifiedRegistrationLandingPageActionLogin]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.simplifiedRegistrationLandingPageActionLogin,
        session_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.simplifiedRegistrationFormPage]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.simplifiedRegistrationFormPage,
        session_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.simplifiedRegistrationFormPageRegister]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.simplifiedRegistrationFormPageRegister,
        session_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.simplifiedRegistrationSuccessPage]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.simplifiedRegistrationSuccessPage,
        session_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.simplifiedRegistrationSuccessPageActionDismiss]: (
    payload = {},
  ) => {
    sendEvent({
      data: {
        evn: eventNames.simplifiedRegistrationSuccessPageActionDismiss,
        session_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.registerPinCreationVisit]: () => {
    sendEvent({
      data: {
        evn: eventNames.registerPinCreationVisit,
        session_id: SESSION_ID,
      },
    });
  },
  [eventNames.registerPinCreationAction]: ({ action }) => {
    sendEvent({
      data: {
        evn: eventNames.registerPinCreationAction,
        session_id: SESSION_ID,
        action,
      },
    });
  },
};

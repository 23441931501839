import { sendEvent } from "@/utils/internal_tracker_instance";
import { generateSessionId } from "@/utils/generate_session_id";
import * as eventNames from "@/constants/event_names/login";

const {
  user: { identity },
} = global["__ACCOUNTS_FE_CONTEXT__"];
const SESSION_ID = generateSessionId(identity);

export const verificationHandler = {
  [eventNames.otpConfirmationVisit]: ({ method, feature }) => {
    sendEvent({
      data: {
        evn: eventNames.otpConfirmationVisit,
        session_id: SESSION_ID,
        method,
        feature,
      },
    });
  },
  [eventNames.otpConfirmationAction]: ({ method, action, feature }) => {
    sendEvent({
      data: {
        evn: eventNames.otpConfirmationAction,
        session_id: SESSION_ID,
        method,
        action,
        feature,
      },
    });
  },
  [eventNames.centralizeVisit]: ({ shownMethods, feature }) => {
    sendEvent({
      data: {
        evn: eventNames.centralizeVisit,
        session_id: SESSION_ID,
        shown_methods: shownMethods,
        feature,
      },
    });
  },
  [eventNames.centralizeAction]: ({ action, feature }) => {
    sendEvent({
      data: {
        evn: eventNames.centralizeAction,
        session_id: SESSION_ID,
        action,
        feature,
      },
    });
  },
};

const accounts = global["__ACCOUNTS_FE_CONTEXT__"];
const {
  APP: { mpAndroidClientId },
} = accounts;

import { getClientIdUrl } from "./get_client_id_url";

export function isAndroid() {
  return !!window.android;
}

export function isIos() {
  return !!window.webkit;
}

export function isWebview() {
  return isAndroid() || isIos();
}

export function isAndroidMpWebview() {
  const comebackUrl = new URL(window.location.href).searchParams.get(
    "comeback",
  );
  if (!comebackUrl) {
    return false;
  }
  const urlClientId = getClientIdUrl(comebackUrl);
  if (!urlClientId) {
    return false;
  }
  return isAndroid() && String(mpAndroidClientId).includes(urlClientId);
}

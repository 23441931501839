/**
 * List of Verticals
 */

const verticals = ["sparca", "gorillatech", "yuap", "bling"];

/**
 * List Of Event Names
 *
 * always user verticals as part of definition so it will works for all verticals
 */

export const vertical_screen_error = verticals.map(
  (vertical) => `${vertical}_screen_error`,
);

/**
 * Getter Function
 */

// Dynamic Function to get event name by vertical
export const getVerticalScreenError = (verticalId) => {
  if (!verticals.includes(verticalId)) {
    return "";
  }
  return `${verticalId}_screen_error`;
};

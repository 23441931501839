export const loginPage = "login_page";
export const login = "login";
export const loginPressRegister = "login_press_register";
export const loginForgetPassword = "login_forget_password";
export const clickPilihOtpButton = "click_pilih_otp_button";
export const inputPasswordLoginSecretCode = "input_password_login_secret_code";
export const loginPinCreationVisit = "login_pin_creation_visit";
export const loginPinCreationAction = "login_pin_creation_action";
export const loginOtpConfirmationVisit = "login_otp_confirmation_visit";
export const loginOtpConfirmationAction = "login_otp_confirmation_action";
export const loginCentralizeVisit = "login_centralize_visit";
export const loginCentralizeAction = "login_centralize_action";
export const inputPasswordAction = "input_password_action";
export const pinValidateVisit = "pin_validate_visit";
export const pinValidateAction = "pin_validate_action";
export const pinValidateSubmit = "pin_validate_submit";
export const otpConfirmationVisit = "otp_confirmation_visit";
export const otpConfirmationAction = "otp_confirmation_action";
export const centralizeVisit = "centralize_visit";
export const centralizeAction = "centralize_action";
export const clientOtpKeyLog = "client_otp_key_log";

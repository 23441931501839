import { sendEvent } from "@/utils/internal_tracker_instance";
import { generateSessionId } from "@/utils/generate_session_id";
import * as eventNames from "@/constants/event_names/login";
import getRefferalTracker from "@/utils/get_referral_tracker";
import otpKeyCensorship from "@/utils/otp_key_censorship";

const {
  user: { identity },
} = global["__ACCOUNTS_FE_CONTEXT__"];
const SESSION_ID = generateSessionId(identity);

export const loginHandler = {
  [eventNames.loginPage]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.loginPage,
        session_id: SESSION_ID,
        login_variant: "normal",
        page_referrer: getRefferalTracker() || "",
        ...payload,
      },
    });
  },
  [eventNames.login]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.login,
        session_id: SESSION_ID,
        page_referrer: getRefferalTracker() || "",
        login_option: "sms_otp", // current only option, WIP, comma separated without space, options: password, sms_otp, wa_otp, pin, email_otp
        login_method: "sms_otp",
        login_method_tfa: "sms_otp",
        login_variant: "normal",
        ...payload,
      },
    });
  },
  [eventNames.loginPressRegister]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.loginPressRegister,
        session_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.loginForgetPassword]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.loginForgetPassword,
        session_id: SESSION_ID,
        page_referrer: getRefferalTracker() || "",
        login_variant: "normal",
        ...payload,
      },
    });
  },
  [eventNames.inputPasswordLoginSecretCode]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.inputPasswordLoginSecretCode,
        session_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.loginPinCreationVisit]: () => {
    sendEvent({
      data: {
        evn: eventNames.loginPinCreationVisit,
        session_id: SESSION_ID,
      },
    });
  },
  [eventNames.loginPinCreationAction]: ({ action }) => {
    sendEvent({
      data: {
        evn: eventNames.loginPinCreationAction,
        session_id: SESSION_ID,
        action,
      },
    });
  },
  [eventNames.loginOtpConfirmationVisit]: ({ method }) => {
    sendEvent({
      data: {
        evn: eventNames.loginOtpConfirmationVisit,
        session_id: SESSION_ID,
        method,
      },
    });
  },
  [eventNames.loginOtpConfirmationAction]: ({ method, action }) => {
    sendEvent({
      data: {
        evn: eventNames.loginOtpConfirmationAction,
        session_id: SESSION_ID,
        method,
        action,
      },
    });
  },
  [eventNames.loginCentralizeVisit]: ({ shownMethods }) => {
    sendEvent({
      data: {
        evn: eventNames.loginCentralizeVisit,
        session_id: SESSION_ID,
        shown_methods: shownMethods,
      },
    });
  },
  [eventNames.loginCentralizeAction]: ({ action }) => {
    sendEvent({
      data: {
        evn: eventNames.loginCentralizeAction,
        session_id: SESSION_ID,
        action,
      },
    });
  },
  [eventNames.inputPasswordAction]: ({ action }) => {
    sendEvent({
      data: {
        evn: eventNames.inputPasswordAction,
        session_id: SESSION_ID,
        action,
      },
    });
  },
  [eventNames.pinValidateVisit]: ({ referrer }) => {
    sendEvent({
      data: {
        evn: eventNames.pinValidateVisit,
        session_id: SESSION_ID,
        referrer,
      },
    });
  },
  [eventNames.pinValidateAction]: ({ action }) => {
    sendEvent({
      data: {
        evn: eventNames.pinValidateAction,
        session_id: SESSION_ID,
        action,
      },
    });
  },
  [eventNames.pinValidateSubmit]: ({ error, attempt }) => {
    sendEvent({
      data: {
        evn: eventNames.pinValidateSubmit,
        session_id: SESSION_ID,
        error,
        attempt,
      },
    });
  },
  [eventNames.clientOtpKeyLog]: ({ triggeredFrom, source, otpKey }) => {
    sendEvent({
      data: {
        evn: eventNames.clientOtpKeyLog,
        session_id: SESSION_ID,
        triggered_from: triggeredFrom,
        source,
        censored_otp_key: otpKeyCensorship(otpKey),
      },
    });
  },
};

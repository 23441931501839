export const simplifiedRegistrationLandingPage =
  "simplified_registration_landing_page";
export const simplifiedRegistrationLandingPageActionRegister =
  "simplified_registration_landing_page_action_register";
export const simplifiedRegistrationLandingPageActionLogin =
  "simplified_registration_landing_page_action_login";
export const simplifiedRegistrationFormPage =
  "simplified_registration_form_page";
export const simplifiedRegistrationFormPageRegister =
  "simplified_registration_form_page_register";
export const simplifiedRegistrationSuccessPage =
  "simplified_registration_success_page";
export const simplifiedRegistrationSuccessPageActionDismiss =
  "simplified_registration_success_page_action_dismiss";
export const registerPinCreationVisit = "register_pin_creation_visit";
export const registerPinCreationAction = "register_pin_creation_action";

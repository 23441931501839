import { sendEvent } from "@/utils/internal_tracker_instance";
import { generateSessionId } from "@/utils/generate_session_id";
import * as eventNames from "@/constants/event_names/captcha";

const {
  user: { identity },
} = global["__ACCOUNTS_FE_CONTEXT__"];
const SESSION_ID = generateSessionId(identity);

/**
  Feature: register | login
  Last event:
  - Register: beforeOtpFilling, otpFilling, inputFullname
  - Login: BeforeOtpFilling, otpFilling, inputPassword, pinSubmit
*/

export const captchaHandler = {
  [eventNames.captchaVisit]: ({ lastEvent, feature }) => {
    sendEvent({
      data: {
        evn: eventNames.captchaVisit,
        session_id: SESSION_ID,
        last_event: lastEvent,
        feature,
      },
    });
  },
};

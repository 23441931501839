import UAParser from "ua-parser-js";
import Tracker from "@bukalapak/tracker-js";
import { viewport } from "@bukalapak/toolbox-helper/browser-helper";
import getPlatform from "./get_platform_for_tracker";
import { generateClientRequestId } from "./generate_client_request_id";
import {
  clientOtpKeyLog,
  pageviews,
  agenliteLoginRegisterVisit,
  agenliteScreenVisit,
  agenliteClick,
  agenliteLoginRegisterClicked,
  agenliteAccountClick,
  vertical_screen_error,
} from "@/constants/event_names";

const accounts = global["__ACCOUNTS_FE_CONTEXT__"];
const {
  user,
  trackerConfig,
  APP: { env },
} = accounts;
const hostname = window.location.hostname.split(".")[0];
const platform = getPlatform();
const userId = user.id;
const userId36 = userId ? parseInt(userId).toString(36) : "";
const internalTrackerUrl = `${trackerConfig.baseUrl}/e`;
const uap = new UAParser(window.navigator.userAgent);
const browser = uap.getBrowser();
const os = uap.getOS();
const { referrer } = document;
const isProduction =
  env === "production" && !window.location.hostname.includes("preproduction.");

const commonData = {
  browser: browser.name,
  browser_version: browser.version,
  td_os_type: os.name,
  td_os_ver: os.version,
  platform,
  ui: userId36,
  identity: user.identity, // TODO: handle empty identity
};

const webTds = {
  td_host: window.location.hostname,
  td_title: document.title,
  td_path: window.location.pathname,
  td_url: window.location.href,
  td_viewport: viewport(),
  td_referrer: referrer,
};

function cleanPayload(payload) {
  for (let key in payload) {
    if (payload[key] === undefined) {
      delete payload[key];
    }
  }
  return payload;
}

const excludedClientRequestId = [
  pageviews,
  clientOtpKeyLog,
  agenliteLoginRegisterVisit,
  agenliteScreenVisit,
  agenliteClick,
  agenliteLoginRegisterClicked,
  agenliteAccountClick,
  ...vertical_screen_error,
];

function sendEvent({ data }) {
  const payload = cleanPayload({
    ...commonData,
    ...data,
  });

  if (!excludedClientRequestId.includes(data.evn)) {
    payload.client_request_id = generateClientRequestId();
  }

  if (global.navigator.sendBeacon && isProduction) {
    global.navigator.sendBeacon(internalTrackerUrl, JSON.stringify(payload));
  } else {
    Tracker.event(payload);
  }
}

Tracker.config({
  baseUrl: trackerConfig.baseUrl,
  dev: accounts.APP.env !== "production",
  platform,
});

export { sendEvent, webTds };

import { sendEvent } from "@/utils/internal_tracker_instance";
import { generateSessionId } from "@/utils/generate_session_id";
import * as eventNames from "@/constants/event_names/authentication_vertical";

const {
  user: { identity },
} = global["__ACCOUNTS_FE_CONTEXT__"];
const SESSION_ID = generateSessionId(identity);

export const authenticationVerticalHandler = {
  ...eventNames.vertical_screen_error.reduce((handlers, eventName) => {
    handlers[eventName] = (payload: {}) => {
      sendEvent({
        data: {
          evn: eventName,
          session_id: SESSION_ID,
          t: Date.now(),
          ...payload,
        },
      });
    };
    return handlers;
  }, {}),
};

import Cookies from "js-cookie";

const base64regex =
  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
let sessionId;

function isBase64(str) {
  return base64regex.test(str);
}

function getSessionIdFromParams() {
  const key = "tsid";
  const params = new URLSearchParams(window.location.search);
  const sessionIdFromParams = params.get(key);
  if (!isBase64(sessionIdFromParams)) return "";
  return sessionIdFromParams;
}

export function generateSessionId(identity = "") {
  // check on params, if exist use it as session id
  const sessionIdParams = getSessionIdFromParams();
  if (sessionIdParams) {
    return sessionIdParams;
  }

  if (sessionId) {
    return sessionId;
  }

  const cookiesSessionId = Cookies.get("session_id");
  if (cookiesSessionId) {
    sessionId = cookiesSessionId;
    return sessionId;
  }

  const timestamp = Date.now();
  const newSessionId = btoa(`${identity}_${timestamp}`);
  sessionId = newSessionId;
  return sessionId;
}

export default generateSessionId;

import { isAndroid, isIos } from "@/utils/is_webview";
const accounts = global["__ACCOUNTS_FE_CONTEXT__"];
const { isSmallScreen } = accounts;

export default () => {
  if (isAndroid()) return "android";
  if (isIos()) return "ios";
  if (isSmallScreen) return "mweb";
  if (!isSmallScreen) return "desktop";
  return "other";
};

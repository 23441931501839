import { webTds, sendEvent } from "@/utils/internal_tracker_instance";
import * as eventNames from "@/constants/event_names";
import { registerHandler } from "./handler/register";
import { loginHandler } from "./handler/login";
import { verificationHandler } from "./handler/verification";
import { authenticationVerticalHandler } from "./handler/authentication_vertical";
import { captchaHandler } from "./handler/captcha";
import { generateSessionId } from "@/utils/generate_session_id";

const {
  user: { identity },
} = global["__ACCOUNTS_FE_CONTEXT__"];
const SESSION_ID = generateSessionId(identity);

export const eventTrackerHandler = {
  [eventNames.example1]: (payload = {}) => {
    console.log(eventNames.example1);
    sendEvent({
      data: {
        evn: eventNames.example1,
        ...payload,
      },
    });
  },
  [eventNames.example2]: (payload = {}) => {
    console.log(eventNames.example2);
    sendEvent({
      data: {
        evn: eventNames.example2,
        ...payload,
      },
    });
  },
  [eventNames.pageviews]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.pageviews,
        ...webTds,
        ...payload,
      },
    });
  },
  [eventNames.agenliteLoginRegisterVisit]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.agenliteLoginRegisterVisit,
        journey_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.clickPilihOtpButton]: (payload = {}) => {
    sendEvent({
      data: {
        ...payload,
        url: webTds.td_url,
      },
    });
  },
  [eventNames.agenliteClick]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.agenliteClick,
        journey_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.agenliteLoginRegisterClicked]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.agenliteLoginRegisterClicked,
        journey_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.agenliteScreenVisit]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.agenliteScreenVisit,
        journey_id: SESSION_ID,
        ...payload,
      },
    });
  },
  [eventNames.agenliteAccountClick]: (payload = {}) => {
    sendEvent({
      data: {
        evn: eventNames.agenliteAccountClick,
        journey_id: SESSION_ID,
        ...payload,
      },
    });
  },
  ...loginHandler,
  ...registerHandler,
  ...verificationHandler,
  ...captchaHandler,
  ...authenticationVerticalHandler,
};

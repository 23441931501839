// get referral from url params `trefurl`
const urlParamsKey = "trefurl";

const getRefferalTracker = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const referral = urlParams.get(urlParamsKey);
  return referral || document.referrer || "";
};

export default getRefferalTracker;

function otpKeyCensorship(inputString) {
  const uncensoredStringLength = 6;
  const otpKeyLength = 36;
  if (
    !inputString ||
    inputString.length < otpKeyLength ||
    typeof inputString !== "string"
  )
    return "";
  const asterisks = "*".repeat(inputString.length - uncensoredStringLength);
  const outputString = inputString.replace(
    /^(.{3}).+(.{3})$/,
    `$1${asterisks}$2`,
  );
  return outputString;
}

export default otpKeyCensorship;
